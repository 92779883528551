import React from "react"
import Layout from "../components/Layout"
import Divider from "../components/Divider"
import ArrowLink from "../components/ArrowLink"

const NotFound = () => {
  return (
    <Layout noFooter={true}>
      <div
        css={{
          display: "inline-block",
        }}
      >
        <h1
          css={{
            fontSize: "2.75rem",
            fontWeight: 600,
            margin: 0,
            display: "inline",
          }}
        >
          Oups ! Erreur&nbsp;404{" "}
          <span role="img" aria-label="link">
            🧐
          </span>
          <Divider />
        </h1>
      </div>
      <div css={{ marginTop: "1.5rem" }}>
        <ArrowLink  isBackArrow to="/">Retour à l'accueil</ArrowLink>
      </div>
    </Layout>
  )
}

export default NotFound
